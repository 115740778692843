<template>
    <SimpleTable formclass="Calendar/form" endpoint="calendars" :aditionalColumns="headers">
        <template>
            <h1>Cadastro de turnos</h1>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";

export default {
    components: { SimpleTable },

    data() {
        return {
            headers: [{ text: this.$t("Loja"), value: "store", align: "right", sortable: true }],
        };
    },
};
</script>